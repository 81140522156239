import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/seo";

const Contact = ({location}) => {
  return (
    <>
      <PageWrapper>
        <SEO title="Page not found" />
        <div className="404-page bg-default min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 px-lg-9">
                {/* <!-- card start --> */}
                <div
                  className="card-404 text-center"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {/* <!-- card-icon start --> */}
                  <div className="404-texts pt-14">
                    <h3 className="card-title font-size-9 font-weight-bold">
                      Page is not found!
                    </h3>
                    <Link
                      to="/"
                      className="btn btn-h-60 rounded-4 w-180 m-auto text-uppercase"
                    >
                      Back to home
                    </Link>
                  </div>
                </div>
                {/* <!-- card end --> */}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
